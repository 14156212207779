import { ChevronRight } from 'lucide-react';
import React from 'react';
import { array, string } from 'prop-types';

import css from './UserSection.module.css';

import ResponsiveImage from '../../../components/ResponsiveImage/ResponsiveImage';

const UserSection = props => {
  const { sectionId, sectionData } = props;
  const titleText = (sectionData && sectionData[0]?.title?.content) || '';
  const descriptionText = (sectionData && sectionData[0]?.description?.content) || '';
  const blocks = (sectionData && sectionData[0]?.blocks) || [];

  const getVariantNames = img => {
    const { variants } = img?.attributes || {};
    return variants ? Object.keys(variants) : [];
  };

  return (
    <section id={sectionId} className={css.container}>
      <div className={css.wrapper}>
        <div className={css.intro}>
          <h2>{titleText}</h2>
          <p className={css.description}>{descriptionText}</p>
        </div>
        <div className={css.blocks}>
          <div className={css.left}>
            <a href={blocks[0].callToAction.href}>
              <div className={css.imageContainer}>
                <ResponsiveImage
                  className={css.backgroundImage}
                  alt={blocks[0].media.alt}
                  image={blocks[0].media.image}
                  variants={getVariantNames(blocks[0].media.image)}
                />
                <h3>{blocks[0].title.content}</h3>
                <div className={css.cta}>
                  <div className={css.ctaContent}>
                    <p>{blocks[0].callToAction.content}</p>
                  </div>
                  <div className={css.ctaAction}>
                    <div className={css.iconContainer}>
                      <ChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className={css.right}>
            <a href={blocks[1].callToAction.href}>
              <div className={css.imageContainer}>
                <ResponsiveImage
                  className={css.backgroundImage}
                  alt={blocks[1].media.alt}
                  image={blocks[1].media.image}
                  variants={getVariantNames(blocks[1].media.image)}
                />
                <h3>{blocks[1].title.content}</h3>
                <div className={css.cta}>
                  <div className={css.ctaContent}>
                    <p>{blocks[1].callToAction.content}</p>
                  </div>
                  <div className={css.ctaAction}>
                    <div className={css.iconContainer}>
                      <ChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

UserSection.defaultProps = {
  sectionData: {},
};

UserSection.propTypes = {
  sectionId: string.isRequired,
  sectionData: array,
};

export default UserSection;
