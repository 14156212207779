import classNames from 'classnames';
import React from 'react';
import { array, string } from 'prop-types';
import { ArrowRight } from 'lucide-react';

import css from './MainSection.module.css';
import { IconBookyrslfArrow } from '../../../components';

const MAX_MOBILE_VIDEO_SCREEN_WIDTH = 768;
const MAX_MOBILE_SCREEN_WIDTH = 1024;
const CTA_OVERLAY_HEIGHT = 114;
const TOPBAR_HEIGHT_MOBILE = 106;
const TOPBAR_HEIGHT_DESKTOP = 86;

// Title animation activation scroll heights
const TITLE_Y_MOBILE = 0;
const TITLE_Y_DESKTOP = 0;

// Logo scaling
const SCALE_MIN = 0.125;
const SCALE_FACTOR_MOBILE = 24.0;
const SCALE_FACTOR_DESKTOP = 18.0;

const MainSection = props => {
  const { sectionId, sectionData } = props;
  const titleText = (sectionData && sectionData[0]?.title?.content) || '';
  const descriptionText = (sectionData && sectionData[0]?.description?.content) || '';
  const ctaText = (sectionData && sectionData[0]?.callToAction?.content) || '';
  const ctaLeft = (sectionData && sectionData[0]?.blocks[0]?.callToAction) || {};
  const ctaRight = (sectionData && sectionData[0]?.blocks[1]?.callToAction) || {};

  const hasWindow = typeof window !== 'undefined';

  const [animateTitle, setAnimateTitle] = React.useState(false);
  const [showCTA, setShowCTA] = React.useState(false);
  const [showDescription, setShowDescription] = React.useState(false);
  const [fixed, setFixed] = React.useState(true);
  const [screenHeight, setScreenHeight] = React.useState(hasWindow ? window.innerHeight : 0);
  const [scale, setScale] = React.useState(SCALE_MIN);
  const [descTop, setDescTop] = React.useState(hasWindow ? window.innerHeight - CTA_OVERLAY_HEIGHT : 0);
  const [isMedium, setIsMedium] = React.useState(hasWindow ? (window.innerWidth >= MAX_MOBILE_SCREEN_WIDTH) : false);
  const [isMobileVideo, setIsMobileVideo] = React.useState(hasWindow ? (window.innerWidth <= MAX_MOBILE_VIDEO_SCREEN_WIDTH) : false);
  const [barHeight, setBarHeight] = React.useState(isMedium ? TOPBAR_HEIGHT_DESKTOP : TOPBAR_HEIGHT_MOBILE);

  const handleScroll = () => {
    if (window.scrollY < 1.5 * screenHeight) {
      const title = window.scrollY > (isMedium ? TITLE_Y_DESKTOP : TITLE_Y_MOBILE);
      const scale = SCALE_MIN + Math.max(0, ((window.scrollY / screenHeight) * (isMedium ? SCALE_FACTOR_DESKTOP : SCALE_FACTOR_MOBILE)));

      setAnimateTitle(title);
      setScale(scale);
      setShowCTA(window.scrollY > (screenHeight / 4));
      setShowDescription(window.scrollY > screenHeight / 4);
    }

    setFixed(window.scrollY < (isMedium ? 1 * screenHeight : 0.75 * screenHeight));

    const offset = isMedium ? 1 * screenHeight : 0.75 * screenHeight;
    const percentage = Math.max(0, ((2 * window.scrollY - offset) / offset));
    const newTop = Math.max(barHeight, window.innerHeight - (percentage * window.innerHeight));
    setDescTop(newTop);
  };

  const handleResize = () => {
    setBarHeight(isMedium ? TOPBAR_HEIGHT_DESKTOP : TOPBAR_HEIGHT_MOBILE);
    setScreenHeight(window.innerHeight);
    setIsMedium(window.innerWidth >= MAX_MOBILE_SCREEN_WIDTH);
    setIsMobileVideo(window.innerWidth <= MAX_MOBILE_VIDEO_SCREEN_WIDTH);

    handleScroll();
  }

  React.useEffect(() => {
    setScreenHeight(window.innerHeight);
    setDescTop(window.innerHeight - CTA_OVERLAY_HEIGHT);
    setIsMedium(window.innerWidth >= MAX_MOBILE_SCREEN_WIDTH);
    setIsMobileVideo(window.innerWidth <= MAX_MOBILE_VIDEO_SCREEN_WIDTH);
    setBarHeight(isMedium ? TOPBAR_HEIGHT_DESKTOP : TOPBAR_HEIGHT_MOBILE);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // TODO: Video loading
  // TODO: Morph arrow into circle

  return (
    <section id={sectionId} className={css.container}>
      <div className={css.wrapper}>
        <div className={classNames(css.center, !fixed && css.centerUnfixed)}>
          <video
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            height="100%"
            width="100%"
            src={isMobileVideo ? "https://ik.imagekit.io/iry7rmaup/homepage/video_mobile.mp4?updatedAt=1721833742725" : "https://ik.imagekit.io/iry7rmaup/homepage/video_desktop.mp4?updatedAt=1721833652967"}
          ></video>
          <h1 className={classNames(css.headerA, animateTitle && css.headerAScrolled)}>{titleText.split(" ")[0]}</h1>
          <svg className={css.logo}
            style={{ transform: `scale(${scale})` }}
            xmlns="http://www.w3.org/2000/svg"
            width="100000"
            height="100000"
            viewBox="0 0 100000 100000"
          >
            <path d="M0 0v100000h100000V0Zm50029.629 49124.668c10.875 0 21.75 4.133 30 12.383l375.605 375.605c16.5 16.5 16.5 43.5 0 60l-137.402 137.403c-10.5 10.2-16.512 24.893-15.312 41.093 2.1 27.6 27.614 47.696 55.214 47.696h308.985c47.4 0 93.314 18.9 126.914 52.5l104.394 104.394c24.6 24.3 24.6 63.916 0 88.516l-818.711 818.691c-16.5 16.5-43.5 16.5-60 0l-197.382-197.402c-16.5-16.5-16.5-43.5 0-60l358.183-358.203c17.1-17.1 4.801-46.192-19.199-46.192h-994.785c-23.7 0-42.617-19.197-42.617-42.597v-317.403c0-23.7 19.217-42.597 42.617-42.597h995.097c15 0 26.993-12.012 26.993-27.012 0-7.5-3.006-14.399-8.106-19.199l-357.89-357.891c-16.5-16.5-16.5-43.5 0-60l197.402-197.402c8.25-8.25 19.125-12.383 30-12.383z" />
          </svg>
          <h1 className={classNames(css.headerB, animateTitle && css.headerBScrolled)}>{titleText.split(" ")[1]}</h1>
          <div
            style={{ top: descTop }}
            className={classNames(css.description, showDescription && css.descriptionVisible, !fixed && css.descriptionUnfixed)}>
            <div>
              <IconBookyrslfArrow className={css.arrow} />
              <p>{descriptionText}</p>
            </div>
          </div>
          <div className={classNames(css.ctaOverlay, showCTA && css.ctaOverlayVisible, !fixed && css.ctaOverlayUnfixed)}>
            <p>{ctaText}</p>
            <div className={css.ctaLinks}>
              <a href={ctaLeft.href}>
                <span>{ctaLeft.content}</span>
                <ArrowRight />
              </a>
              <a href={ctaRight.href}>
                <span>{ctaRight.content}</span>
                <ArrowRight />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

MainSection.defaultProps = {
  sectionData: {},
};

MainSection.propTypes = {
  sectionId: string.isRequired,
  sectionData: array,
};

export default MainSection;
