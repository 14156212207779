import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

import ComingSoon from './ComingSoon/ComingSoon';

import MainSection from './MainSection/MainSection';
import UserSection from './UserSection/UserSection';

const SHOW_COMING_SOON = process.env.REACT_APP_COMING_SOON_LANDING_PAGE === 'true';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  if (SHOW_COMING_SOON) {
    return <ComingSoon />;
  }

  // Page data from Sharetribe console
  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  // All the text and images for the sections
  const sections = pageData?.sections;

  const main = sections?.filter(section => section.sectionName === 'main');
  const mainSection = {
    sectionId: 'main',
    sectionType: 'main',
    sectionData: main,
  };

  const user = sections?.filter(section => section.sectionName === 'user');
  const userSection = {
    sectionId: 'user',
    sectionType: 'user',
    sectionData: user,
  };

  const customSections = pageData ? [mainSection, userSection] : pageData?.sections;

  return (
    <PageBuilder
      pageAssetsData={{
        ...pageData,
        sections: customSections,
      }}
      options={{
        sectionComponents: {
          main: {
            component: MainSection,
          },
          user: {
            component: UserSection,
          },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
